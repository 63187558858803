header {
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
	min-height: 5rem;
	z-index: 5;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 3.3rem 1.5rem 0;
	.mobile_logo_image {
		display: none;
	}

	@media(max-width: 992px){
		background: #000000;
		box-shadow: 0px 2px 5px 0px #000;
		position: fixed;
		justify-content: space-between;
		padding: 1rem 1.5rem;
			.mobile-logo {
				//position: absolute;
				left: 30%;
				margin: 0 0 0 44px;
			}
		.mobile_logo_image {
			display: inline-block;
			img {
				width: 130px;
				text-align: center;
			}
		}
	}
	.mobLogo {
		display: none;
		@media(max-width: 992px){
			display: block;
		}
	}
	&.active {
		nav {
			right: 0;
		}
	}
	nav {
		@include fb;
		margin: auto;
		@media(max-width: 992px){
			position: fixed;
			right: -115%;
			padding: 3rem;
			top: 0;
			height: 100%;
			width: 100%;
			overflow: auto;
			transition: .4s;
			flex-direction: column;
			background: #292929;
			* {
				user-select: none;
			}
		}
		a {
			text-transform: uppercase;
			font-family: $al;
			color:$w;
			&:not(.logo){
				font-size: 1.4rem;
				margin: 3rem 6rem 0 0rem;
				@media(max-width: 1150px){
					margin: 3rem 4rem 0 0rem;
				}
				@media(max-width: 1070px){
					margin: 3rem 3rem 0 0rem;
				}
				@media(max-width: 992px){
					margin: .9rem 0;
				}
				&:last-child {
					margin-right: 0;
				}
				&:nth-child(3){
				}
			}
			&.logo {
				//margin: 0 5rem 0 6rem;
				margin: 0 24rem 0 6rem;
				@media(max-width: 1070px){
					margin: 0 3rem 0 4rem;
				}
				@media(max-width: 992px){
					display: block;
					margin: 4.5rem auto;
				}
			}

			&:hover {
				color: $active;
			}
		}
		.close {
			 display: none;
			//width: 0;
			@media(max-width: 992px){
				display: block;
				position: absolute;
				cursor: pointer;
				top: 2rem;
				right: 3rem;
				fill: #fff;
				@include w(4,4);
				&:active {
					fill: $active;
				}
			}
		}
	}
	.mobileBtn {
		display: none;
		width: 4.5rem;
		cursor: pointer;
		background-image: url("app/img/mini-icon.png");
		@media(max-width: 992px){
			display: block;
		}
		span {
			display: block;
			width: 100%;
			height: 2px;
			background:#fff;
			&:nth-child(2) {
				margin: 10px 0;
			}
		}
	}
}
