section {
	&.banner {
		width: 100%;
		min-height: 101rem;
		background: url('../img/banner.png')no-repeat center;
		background-size: cover;
		position: relative;
		padding: 5rem 1.5rem 0;
		@include fb;
		@media(max-width:800px){
			min-height: 30rem;
			padding: 23rem 2rem 11rem;
		}
		@media(max-width: 650px){
			padding: 15rem 2rem 7rem;

		}
		&:before {
			content: '';
			width: 100%;
			position: absolute;
			display: block;
			height: 26rem;
			left: 0;
			top: 0;
			z-index: 1;
			background: rgba(0,0,0,1);
			background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.51) 49%, rgba(0,0,0,0.16) 84%, rgba(0,0,0,0) 100%);
			background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,1)), color-stop(49%, rgba(0,0,0,0.51)), color-stop(84%, rgba(0,0,0,0.16)), color-stop(100%, rgba(0,0,0,0)));
			background: -webkit-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.51) 49%, rgba(0,0,0,0.16) 84%, rgba(0,0,0,0) 100%);
			background: -o-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.51) 49%, rgba(0,0,0,0.16) 84%, rgba(0,0,0,0) 100%);
			background: -ms-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.51) 49%, rgba(0,0,0,0.16) 84%, rgba(0,0,0,0) 100%);
			background: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0.51) 49%, rgba(0,0,0,0.16) 84%, rgba(0,0,0,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
		}
		.container {
			text-align: center;
			max-width: 68rem;
			margin: auto;
			width: 100%;
			max-width: 113rem;
			img {
				max-width: 100%;
			}
		}
		h1 {
			letter-spacing: .7px;
			line-height: 11rem;
			font-size: 7.1rem;
			font-family: $al;
			display: block;
			text-align: center;
			@media(max-width: 800px){
				font-size: 5.1rem;
			}
			@media(max-width: 600px){
				margin: 2rem 0 3rem;
				font-size: 4.1rem;
				line-height: inherit;
			}
		}
		p {
			display: block;
			width: 100%;
			text-align: center;
			width: 100%;
			max-width: 66rem;
			margin: 0 auto 2rem;
			font-size: 1.8rem;
		}
		.btn {
			margin: 4rem auto 0;
		}
	}
	&.products {
		background: #121110;
		padding: 13rem 1.5rem 4rem;
		position: relative;
		z-index: 3;
		.conatiner {
			width: 100%;
		}
		.wrapper {
			display: flex;
			margin: 4.2rem auto -17.6rem;
			align-items: stretch;
			flex-wrap: wrap;
			justify-content: center;
		}
		.col {
			margin: 0 1rem 3rem;
			width: 100%;
			max-width: 46rem;
			min-height: 70rem;
			padding: 1.5rem;
			outline-width: 1px;
			background: #1f1b1a;
			outline: auto;
			outline-style: solid;
			outline-color: #3d322c;
			outline-offset: -1.5rem;
			@include br(#765e4e);
			img {
				display: block;
				margin: auto;
				display: block;
				height: 32.4rem;
				position: relative;
				z-index: 2;
				object-fit: contain;
				margin: 4rem auto 0rem;
			}
			&_face {
				padding: 0 0 4rem 0;
				position: relative;

				&:after {
					content: '';
					width: 100%;
					display: block;
					height: 1px;
					background: #000;
					position: absolute;
					box-shadow: 0px 2px 7px 0px rgba(255, 255, 255, 0.24);
					left: 0;
					bottom: 0;
				}
				&:before {
					content: '';
					width: 100%;
					display: block;
					height: 100%;
					position: absolute;
					left: 0;
					bottom: 0;
					opacity: .25;
					filter: blur(5px);
					background: url('../img/overlay.png')no-repeat center;
					background-size: 99%;
				}

			}
			&_description {
				padding: 2.7rem 5.2rem 2rem;
				min-height: 35rem;
			}
			&_title {
				font-size: 4.4rem;
				color: #c4a483;
				display: block;
				font-family: $al;
				margin-bottom: 1.3rem;
			}
			p {
				font-size: 1.8rem;
				line-height: 1.8;
			}
		}
	}
	&.story {
		min-height: 96rem;
		width: 100%;
		position: relative;
		padding: 30rem 1.5rem 10rem;
		background:transparent url('../img/story-bg.png') right center no-repeat;
		@media(max-width: 992px){
			min-height: 35rem;
			padding: 18rem 1.5rem 10rem;
		}
		&:before {
			content: '';
			width: 100%;
			position: absolute;
			display: block;
			height: 100%;
			opacity: .9;
			left: 0;
			top: 0;
			z-index: 1;
			background: rgba(0,0,0,1);
			background: -moz-linear-gradient(left, rgba(0,0,0,1) 0%, rgba(0,0,0,0.51) 49%, rgba(0,0,0,0.16) 84%, rgba(0,0,0,0) 100%);
			background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,0,0,1)), color-stop(49%, rgba(0,0,0,0.51)), color-stop(84%, rgba(0,0,0,0.16)), color-stop(100%, rgba(0,0,0,0)));
			background: -webkit-linear-gradient(left, rgba(0,0,0,1) 0%, rgba(0,0,0,0.51) 49%, rgba(0,0,0,0.16) 84%, rgba(0,0,0,0) 100%);
			background: -o-linear-gradient(left, rgba(0,0,0,1) 0%, rgba(0,0,0,0.51) 49%, rgba(0,0,0,0.16) 84%, rgba(0,0,0,0) 100%);
			background: -ms-linear-gradient(left, rgba(0,0,0,1) 0%, rgba(0,0,0,0.51) 49%, rgba(0,0,0,0.16) 84%, rgba(0,0,0,0) 100%);
			background: linear-gradient(to right, rgba(0,0,0,1) 0%, rgba(0,0,0,0.51) 49%, rgba(0,0,0,0.16) 84%, rgba(0,0,0,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );
		}
		.block_title {
			text-align: left;
		}
		.container {
			width: 100%;
			position: relative;
			z-index: 2;
			max-width: 137rem;
			padding: 0 1.3rem;
			margin: auto;
		}
		.wrapper {
			display: flex;
			padding-top: 4rem;
			align-items: flex-start;
			justify-content: space-between;
			@media(max-width: 992px){
				display: block;
				div {
					&:first-child {
						margin-bottom: 4rem;
					}
				}
			}
			div {
				width: 100%;
				max-width: 65rem;
				@media(max-width: 1300px){
					max-width: 55rem;
				}
				@media(max-width: 1190px){
					max-width: 45rem;
				}
				@media(max-width: 992px){
					max-width: 100%;
				}
			}
			p {
				font-size: 2rem;
				line-height: 1.8;
				margin-bottom: 4rem;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	&.find {
		background-image: url("../img/find-bg.png");
		background-position: left;
		background-repeat: no-repeat;
		padding: 28rem 0 33rem;
		.find {
			&__title {
				max-width: 80rem;
				margin: 0 auto 4rem;
				text-align: center;
				display: flex;
				justify-content: center;
				flex-direction: column;
				align-items: center;
				color: #ffffff;
				.block_title {
					margin: 0 0 3rem;
				}
				p {
					font-size: 18px;
					font-family:$al;
					text-align: center;
					line-height: 2;
					font-weight: 300;
					padding-bottom: 30px;
				}
			}
			&__items {
				max-width: 98rem;
				margin: 0 auto;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				@media(max-width: 550px){
					display: block;
				}
			}
			&__item {
				display: flex;
				@media(max-width: 992px){
					margin: 2rem auto;
				}
				@media(max-width: 550px){
					display: block;
					text-align: center;
				}
				&__image {
					height: 140px;
					background: #977a5b;
					text-align: center;
					align-items: center;
					padding-top: 20px;
					border-radius: 15px;
					width: 150px;
					@media(max-width: 550px){
						margin: auto;
					}
				}
				&__description {
					padding-left: 50px;
					@media(max-width: 550px){
						padding-left: 0;
					}
					h3 {
						font-family: AlbertusMTStd, sans-serif;
						font-size: 30px;
						font-weight: 300;
						line-height: 2;
						color: white;
					}
					p {
						color: #bc9e7d;
						font-family: marcellus-regular, sans-serif;
						line-height: 2;
						font-size: 14px;
					}
					a {
						color: #bc9e7d;
						font-family: marcellus-regular, sans-serif;
						line-height: 2;
						font-size: 14px;
					}
				}
			}
		}

	}
}
.footer {
	background-image: url("../img/footer-bg.png");
	background-repeat: no-repeat;
	min-height: 100vh;
	&__contact {
		min-height: 60vh;
		max-width: 800px;
		margin: 0 auto;
		display: block;
		h2 {
			font-family: AlbertusMTStd, sans-serif;
			font-size: 44px;
			font-weight: 300;
			padding-top: 100px;
			text-align: center;
			padding-bottom: 20px;
			color: #ffffff;
		}
		p {
			text-align: center;
			font-family: marcellus-regular  , sans-serif;
			font-size: 18px;
			letter-spacing: 0.8px;
			padding-bottom: 70px;
			line-height: 1.8;
			color: #ffffff;
		}
		.footer__button {
			text-align: center;
			padding-bottom:   100px;
		}
		a {
			padding: 30px 70px;
			border: 1px solid #cfa87b;
			border-radius: 40px;
			font-size: 14px;
			color: #cfa87b;
			font-family: MyriadPro-Regular, sans-serif;
			text-transform: uppercase;
		}
	}
	form {
		display: flex;
		flex-direction: column;
		height: 300px;
		width: 70%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 100px;

		label {
			font-size: 20px;
			font-family: marcellus-regular, sans-serif;
			color: #af886a;

		}
		input {
			width: 100%;
			//background-color: #ddbb95;
			background-color: #d4b89e;
			height: 50px;
			border-radius: 20px;
			border: 2px solid #a78156;
			margin: auto;
			padding: 30px;
			font-size: 20px;
			color: black;
			&:focus {
				outline: none;
			}
		}
		button {
			width: 50%;
			height: 50px;
			border-radius: 15px;
			text-align: center;
			background: #6c5641;
			font-size: 20px;
			border: none;
			margin: auto;
			cursor: pointer;
			&:hover {
				background: #af886a;
			}
		}
	}
	&__links {
		min-height: 30vh;
		max-width: 1200px;
		margin: 0 auto;
		display: flex;
		padding: 0 1%;
	}
	&__item {
		flex: 1;
		margin: 0 1%;
		font-family: marcellus-regular, sans-serif;
		font-weight: 300;
		font-size: 14px;
		h2 {
			color: white;
			font-size: 14px;
			font-family: marcellus-regular, sans-serif;
			font-weight: 300;
			padding-bottom: 20px;
			min-width: 100px;
		}
		p {
			//text-align: left;
			color: #a78156;
			line-height: 2;
		}
		&:nth-child(1) {
			p {
				color: #af886a;
			}
		}
		&:nth-child(3) {
			p {
				color: #af886a;
			}
			a {
				&:hover {
					color: white;
				}
			}
		}
		&:last-child {
			flex: 0;
		}
	}
	&__icons{
		display: flex;
		img {
			width: 20px;
			height: 20px;
			margin-right: 10px;
		}
	}

}
.button__wrapper {
	max-width: 880px;
	display: flex;
	position: absolute;
	left: 70%;

	margin-left: -20px;
	margin-right: 0;
}
.button {
	display: flex;
	justify-content: flex-end;
	max-width: 880px;
	margin-top: -170px;

	text-align: left;


	p {
		align-items: center;
		padding: 10px;
		color: #a78156;
		font-size: 18px;
	}
	#myBtn {
		color: #977a5b;
		font-size: 18px;
		vertical-align: middle;
		img {
			&:hover {
				background-color: #ecc59a;
			}
			border: 1px solid #977a5b;
			border-radius: 3px;
			padding: 5px;
			width: 40px;
			height: 40px;
		}
	}
}
@media screen and (max-width: 1300px){
	.footer {
		&__item {
			p {
				width: 350px;
			}
		}
		&__contact {
			h2 {
				font-size: 30px;
			}
			p {
				font-size: 14px;
			}
			a {
				padding: 20px 50px;
			}
			//padding-bottom: 90px;
		}
		&__links {
			flex-direction: column;
			align-items: center;
			text-align: center;
		}
	}
	.button__wrapper {
		left: 45%;
	}
	.button {
		display: flex;
		justify-content: center;
		margin-top: 0;
		padding-right: 30px;
		margin-left: 30px;
		p {
			align-items: center;
			padding: 10px;
			color: #a78156;
			font-size: 18px;
		}
		#myBtn {
			color: #977a5b;
			font-size: 18px;
			vertical-align: middle;
			img {
				&:hover {
					background-color: #ecc59a;
				}
				border: 1px solid #977a5b;
				border-radius: 3px;
				padding: 5px;
				width: 40px;
				height: 40px;
			}
		}
	}
}
