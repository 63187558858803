@font-face {
    font-family: 'Albertus MT Std';
    src: url('../fonts/AlbertusMTStd.woff2') format('woff2'),
    url('../fonts/AlbertusMTStd.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../fonts/MyriadPro-Regular.woff2') format('woff2'),
    url('../fonts/MyriadPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Marcellus';
    src: url('../fonts/Marcellus-Regular.woff2') format('woff2'),
    url('../fonts/Marcellus-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HVD-Fonts-Livory-Bold';
    src: url("../fonts/HVD-Fonts-Livory-Bold.ttf");
}

@font-face {
    font-family: 'HVD-Fonts-Livory-Regular';
    src: url("../fonts/HVD-Fonts-Livory-Regular.ttf");
}

@font-face {
    font-family: 'MUARA';
    src: url("../fonts/MUARA.ttf");
}
