@font-face {
  font-family: 'Albertus MT Std';
  src: url("../fonts/AlbertusMTStd.woff2") format("woff2"), url("../fonts/AlbertusMTStd.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url("../fonts/MyriadPro-Regular.woff2") format("woff2"), url("../fonts/MyriadPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Marcellus';
  src: url("../fonts/Marcellus-Regular.woff2") format("woff2"), url("../fonts/Marcellus-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HVD-Fonts-Livory-Bold';
  src: url("../fonts/HVD-Fonts-Livory-Bold.ttf");
}

@font-face {
  font-family: 'HVD-Fonts-Livory-Regular';
  src: url("../fonts/HVD-Fonts-Livory-Regular.ttf");
}

@font-face {
  font-family: 'MUARA';
  src: url("../fonts/MUARA.ttf");
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
dl,
dt,
dd,
ol,
ul,
li,
button,
fieldset,
form,
input,
label,
legend,
textarea,
select,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
a,
b,
i,
u,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  font-size: 100%;
  font-style: normal;
  font-weight: normal;
  line-height: inherit;
  vertical-align: baseline;
  background: none;
  white-space: normal;
  scroll-behavior: smooth;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: "Myriad Pro";
  color: #fff;
}

form {
  height: inherit;
}

iframe {
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #535353  !important;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

A:link,
A:active,
A:hover,
A:visited {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

header {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  min-height: 5rem;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3.3rem 1.5rem 0;
}

header .mobile_logo_image {
  display: none;
}

header .mobLogo {
  display: none;
}

header.active nav {
  right: 0;
}

header nav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

header nav a {
  text-transform: uppercase;
  font-family: "Albertus MT Std";
  color: #fff;
}

header nav a:not(.logo) {
  font-size: 1.4rem;
  margin: 3rem 6rem 0 0rem;
}

header nav a:not(.logo):last-child {
  margin-right: 0;
}

header nav a.logo {
  margin: 0 24rem 0 6rem;
}

header nav a:hover {
  color: #ebc499;
}

header nav .close {
  display: none;
}

header .mobileBtn {
  display: none;
  width: 4.5rem;
  cursor: pointer;
  background-image: url("app/img/mini-icon.png");
}

header .mobileBtn span {
  display: block;
  width: 100%;
  height: 2px;
  background: #fff;
}

header .mobileBtn span:nth-child(2) {
  margin: 10px 0;
}

section.banner {
  width: 100%;
  min-height: 101rem;
  background: url("../img/banner.png") no-repeat center;
  background-size: cover;
  position: relative;
  padding: 5rem 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

section.banner:before {
  content: '';
  width: 100%;
  position: absolute;
  display: block;
  height: 26rem;
  left: 0;
  top: 0;
  z-index: 1;
  background: black;
  background: -moz-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.51) 49%, rgba(0, 0, 0, 0.16) 84%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, black), color-stop(49%, rgba(0, 0, 0, 0.51)), color-stop(84%, rgba(0, 0, 0, 0.16)), color-stop(100%, rgba(0, 0, 0, 0)));
  background: -webkit-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.51) 49%, rgba(0, 0, 0, 0.16) 84%, rgba(0, 0, 0, 0) 100%);
  background: -o-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.51) 49%, rgba(0, 0, 0, 0.16) 84%, rgba(0, 0, 0, 0) 100%);
  background: -ms-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.51) 49%, rgba(0, 0, 0, 0.16) 84%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0.51) 49%, rgba(0, 0, 0, 0.16) 84%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
}

section.banner .container {
  text-align: center;
  max-width: 68rem;
  margin: auto;
  width: 100%;
  max-width: 113rem;
}

section.banner .container img {
  max-width: 100%;
}

section.banner h1 {
  letter-spacing: .7px;
  line-height: 11rem;
  font-size: 7.1rem;
  font-family: "Albertus MT Std";
  display: block;
  text-align: center;
}

section.banner p {
  display: block;
  width: 100%;
  text-align: center;
  width: 100%;
  max-width: 66rem;
  margin: 0 auto 2rem;
  font-size: 1.8rem;
}

section.banner .btn {
  margin: 4rem auto 0;
}

section.products {
  background: #121110;
  padding: 13rem 1.5rem 4rem;
  position: relative;
  z-index: 3;
}

section.products .conatiner {
  width: 100%;
}

section.products .wrapper {
  display: flex;
  margin: 4.2rem auto -17.6rem;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: center;
}

section.products .col {
  margin: 0 1rem 3rem;
  width: 100%;
  max-width: 46rem;
  min-height: 70rem;
  padding: 1.5rem;
  outline-width: 1px;
  background: #1f1b1a;
  outline: auto;
  outline-style: solid;
  outline-color: #3d322c;
  outline-offset: -1.5rem;
  border: 1px solid #765e4e;
}

section.products .col img {
  display: block;
  margin: auto;
  display: block;
  height: 32.4rem;
  position: relative;
  z-index: 2;
  object-fit: contain;
  margin: 4rem auto 0rem;
}

section.products .col_face {
  padding: 0 0 4rem 0;
  position: relative;
}

section.products .col_face:after {
  content: '';
  width: 100%;
  display: block;
  height: 1px;
  background: #000;
  position: absolute;
  box-shadow: 0px 2px 7px 0px rgba(255, 255, 255, 0.24);
  left: 0;
  bottom: 0;
}

section.products .col_face:before {
  content: '';
  width: 100%;
  display: block;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: .25;
  filter: blur(5px);
  background: url("../img/overlay.png") no-repeat center;
  background-size: 99%;
}

section.products .col_description {
  padding: 2.7rem 5.2rem 2rem;
  min-height: 35rem;
}

section.products .col_title {
  font-size: 4.4rem;
  color: #c4a483;
  display: block;
  font-family: "Albertus MT Std";
  margin-bottom: 1.3rem;
}

section.products .col p {
  font-size: 1.8rem;
  line-height: 1.8;
}

section.story {
  min-height: 96rem;
  width: 100%;
  position: relative;
  padding: 30rem 1.5rem 10rem;
  background: transparent url("../img/story-bg.png") right center no-repeat;
}

section.story:before {
  content: '';
  width: 100%;
  position: absolute;
  display: block;
  height: 100%;
  opacity: .9;
  left: 0;
  top: 0;
  z-index: 1;
  background: black;
  background: -moz-linear-gradient(left, black 0%, rgba(0, 0, 0, 0.51) 49%, rgba(0, 0, 0, 0.16) 84%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, black), color-stop(49%, rgba(0, 0, 0, 0.51)), color-stop(84%, rgba(0, 0, 0, 0.16)), color-stop(100%, rgba(0, 0, 0, 0)));
  background: -webkit-linear-gradient(left, black 0%, rgba(0, 0, 0, 0.51) 49%, rgba(0, 0, 0, 0.16) 84%, rgba(0, 0, 0, 0) 100%);
  background: -o-linear-gradient(left, black 0%, rgba(0, 0, 0, 0.51) 49%, rgba(0, 0, 0, 0.16) 84%, rgba(0, 0, 0, 0) 100%);
  background: -ms-linear-gradient(left, black 0%, rgba(0, 0, 0, 0.51) 49%, rgba(0, 0, 0, 0.16) 84%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to right, black 0%, rgba(0, 0, 0, 0.51) 49%, rgba(0, 0, 0, 0.16) 84%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );
}

section.story .block_title {
  text-align: left;
}

section.story .container {
  width: 100%;
  position: relative;
  z-index: 2;
  max-width: 137rem;
  padding: 0 1.3rem;
  margin: auto;
}

section.story .wrapper {
  display: flex;
  padding-top: 4rem;
  align-items: flex-start;
  justify-content: space-between;
}

section.story .wrapper div {
  width: 100%;
  max-width: 65rem;
}

section.story .wrapper p {
  font-size: 2rem;
  line-height: 1.8;
  margin-bottom: 4rem;
}

section.story .wrapper p:last-child {
  margin-bottom: 0;
}

section.find {
  background-image: url("../img/find-bg.png");
  background-position: left;
  background-repeat: no-repeat;
  padding: 28rem 0 33rem;
}

section.find .find__title {
  max-width: 80rem;
  margin: 0 auto 4rem;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
}

section.find .find__title .block_title {
  margin: 0 0 3rem;
}

section.find .find__title p {
  font-size: 18px;
  font-family: "Albertus MT Std";
  text-align: center;
  line-height: 2;
  font-weight: 300;
  padding-bottom: 30px;
}

section.find .find__items {
  max-width: 98rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

section.find .find__item {
  display: flex;
}

section.find .find__item__image {
  height: 140px;
  background: #977a5b;
  text-align: center;
  align-items: center;
  padding-top: 20px;
  border-radius: 15px;
  width: 150px;
}

section.find .find__item__description {
  padding-left: 50px;
}

section.find .find__item__description h3 {
  font-family: AlbertusMTStd, sans-serif;
  font-size: 30px;
  font-weight: 300;
  line-height: 2;
  color: white;
}

section.find .find__item__description p {
  color: #bc9e7d;
  font-family: marcellus-regular, sans-serif;
  line-height: 2;
  font-size: 14px;
}

section.find .find__item__description a {
  color: #bc9e7d;
  font-family: marcellus-regular, sans-serif;
  line-height: 2;
  font-size: 14px;
}

.footer {
  background-image: url("../img/footer-bg.png");
  background-repeat: no-repeat;
  min-height: 100vh;
}

.footer__contact {
  min-height: 60vh;
  max-width: 800px;
  margin: 0 auto;
  display: block;
}

.footer__contact h2 {
  font-family: AlbertusMTStd, sans-serif;
  font-size: 44px;
  font-weight: 300;
  padding-top: 100px;
  text-align: center;
  padding-bottom: 20px;
  color: #ffffff;
}

.footer__contact p {
  text-align: center;
  font-family: marcellus-regular  , sans-serif;
  font-size: 18px;
  letter-spacing: 0.8px;
  padding-bottom: 70px;
  line-height: 1.8;
  color: #ffffff;
}

.footer__contact .footer__button {
  text-align: center;
  padding-bottom: 100px;
}

.footer__contact a {
  padding: 30px 70px;
  border: 1px solid #cfa87b;
  border-radius: 40px;
  font-size: 14px;
  color: #cfa87b;
  font-family: MyriadPro-Regular, sans-serif;
  text-transform: uppercase;
}

.footer form {
  display: flex;
  flex-direction: column;
  height: 300px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
}

.footer form label {
  font-size: 20px;
  font-family: marcellus-regular, sans-serif;
  color: #af886a;
}

.footer form input {
  width: 100%;
  background-color: #d4b89e;
  height: 50px;
  border-radius: 20px;
  border: 2px solid #a78156;
  margin: auto;
  padding: 30px;
  font-size: 20px;
  color: black;
}

.footer form input:focus {
  outline: none;
}

.footer form button {
  width: 50%;
  height: 50px;
  border-radius: 15px;
  text-align: center;
  background: #6c5641;
  font-size: 20px;
  border: none;
  margin: auto;
  cursor: pointer;
}

.footer form button:hover {
  background: #af886a;
}

.footer__links {
  min-height: 30vh;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  padding: 0 1%;
}

.footer__item {
  flex: 1;
  margin: 0 1%;
  font-family: marcellus-regular, sans-serif;
  font-weight: 300;
  font-size: 14px;
}

.footer__item h2 {
  color: white;
  font-size: 14px;
  font-family: marcellus-regular, sans-serif;
  font-weight: 300;
  padding-bottom: 20px;
  min-width: 100px;
}

.footer__item p {
  color: #a78156;
  line-height: 2;
}

.footer__item:nth-child(1) p {
  color: #af886a;
}

.footer__item:nth-child(3) p {
  color: #af886a;
}

.footer__item:nth-child(3) a:hover {
  color: white;
}

.footer__item:last-child {
  flex: 0;
}

.footer__icons {
  display: flex;
}

.footer__icons img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.button__wrapper {
  max-width: 880px;
  display: flex;
  position: absolute;
  left: 70%;
  margin-left: -20px;
  margin-right: 0;
}

.button {
  display: flex;
  justify-content: flex-end;
  max-width: 880px;
  margin-top: -170px;
  text-align: left;
}

.button p {
  align-items: center;
  padding: 10px;
  color: #a78156;
  font-size: 18px;
}

.button #myBtn {
  color: #977a5b;
  font-size: 18px;
  vertical-align: middle;
}

.button #myBtn img {
  border: 1px solid #977a5b;
  border-radius: 3px;
  padding: 5px;
  width: 40px;
  height: 40px;
}

.button #myBtn img:hover {
  background-color: #ecc59a;
}

html {
  font-size: 10px;
  background-color: #121110;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 23rem;
  height: 7rem;
  text-transform: uppercase;
  font-size: 1.5rem;
  border-radius: 10rem;
  background: transparent;
  border: 2px solid #ebc499;
  cursor: pointer;
  color: #ebc499;
}

.btn:hover {
  background: #ebc499;
  color: #000;
}

.block_title {
  font-size: 4.4rem;
  display: block;
  text-align: center;
  font-family: "Albertus MT Std";
}

@media (min-width: 1921px) {
  html {
    font-size: 1.2vh;
  }
}

@media (max-width: 1300px) {
  section.story .wrapper div {
    max-width: 55rem;
  }
}

@media screen and (max-width: 1300px) {
  .footer__item p {
    width: 350px;
  }

  .footer__contact h2 {
    font-size: 30px;
  }

  .footer__contact p {
    font-size: 14px;
  }

  .footer__contact a {
    padding: 20px 50px;
  }

  .footer__links {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .button__wrapper {
    left: 45%;
  }

  .button {
    display: flex;
    justify-content: center;
    margin-top: 0;
    padding-right: 30px;
    margin-left: 30px;
  }

  .button p {
    align-items: center;
    padding: 10px;
    color: #a78156;
    font-size: 18px;
  }

  .button #myBtn {
    color: #977a5b;
    font-size: 18px;
    vertical-align: middle;
  }

  .button #myBtn img {
    border: 1px solid #977a5b;
    border-radius: 3px;
    padding: 5px;
    width: 40px;
    height: 40px;
  }

  .button #myBtn img:hover {
    background-color: #ecc59a;
  }
}

@media (max-width: 1190px) {
  section.story .wrapper div {
    max-width: 45rem;
  }
}

@media (max-width: 1150px) {
  header nav a:not(.logo) {
    margin: 3rem 4rem 0 0rem;
  }
}

@media (max-width: 1070px) {
  header nav a:not(.logo) {
    margin: 3rem 3rem 0 0rem;
  }

  header nav a.logo {
    margin: 0 3rem 0 4rem;
  }
}

@media (max-width: 992px) {
  header {
    background: #000000;
    box-shadow: 0px 2px 5px 0px #000;
    position: fixed;
    justify-content: space-between;
    padding: 1rem 1.5rem;
  }

  header .mobile-logo {
    left: 30%;
    margin: 0 0 0 44px;
  }

  header .mobile_logo_image {
    display: inline-block;
  }

  header .mobile_logo_image img {
    width: 130px;
    text-align: center;
  }

  header .mobLogo {
    display: block;
  }

  header nav {
    position: fixed;
    right: -115%;
    padding: 3rem;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: auto;
    transition: .4s;
    flex-direction: column;
    background: #292929;
  }

  header nav * {
    user-select: none;
  }

  header nav a:not(.logo) {
    margin: .9rem 0;
  }

  header nav a.logo {
    display: block;
    margin: 4.5rem auto;
  }

  header nav .close {
    display: block;
    position: absolute;
    cursor: pointer;
    top: 2rem;
    right: 3rem;
    fill: #fff;
    min-width: 4rem;
    max-width: 4rem;
    width: 100%;
    height: 4rem;
    min-height: 4rem;
  }

  header nav .close:active {
    fill: #ebc499;
  }

  header .mobileBtn {
    display: block;
  }

  section.story {
    min-height: 35rem;
    padding: 18rem 1.5rem 10rem;
  }

  section.story .wrapper {
    display: block;
  }

  section.story .wrapper div:first-child {
    margin-bottom: 4rem;
  }

  section.story .wrapper div {
    max-width: 100%;
  }

  section.find .find__item {
    margin: 2rem auto;
  }
}

@media (max-width: 800px) {
  section.banner {
    min-height: 30rem;
    padding: 23rem 2rem 11rem;
  }

  section.banner h1 {
    font-size: 5.1rem;
  }
}

@media (max-width: 650px) {
  section.banner {
    padding: 15rem 2rem 7rem;
  }
}

@media (max-width: 600px) {
  section.banner h1 {
    margin: 2rem 0 3rem;
    font-size: 4.1rem;
    line-height: inherit;
  }
}

@media (max-width: 550px) {
  section.find .find__items {
    display: block;
  }

  section.find .find__item {
    display: block;
    text-align: center;
  }

  section.find .find__item__image {
    margin: auto;
  }

  section.find .find__item__description {
    padding-left: 0;
  }
}