@mixin fb {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin cont($max_width: 122.5) {
  width: 100%;
  max-width: $max_width + rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

@mixin br($c: red) {
  border: 1px solid $c;
}

@mixin abs($position: absolute) {
  position: $position;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@mixin overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  z-index: 1;
  background: rgba(73, 86, 103, 0.67);
}

@mixin w($w: auto, $h: auto) {
  min-width: $w + rem;
  max-width: $w + rem;
  width: 100%;
  height: $h + rem;
  min-height: $h + rem;
}  



$w: #fff;
$active: #ebc499;

$al:'Albertus MT Std';
$mp:'Myriad Pro';
$mar:'Marcellus';
