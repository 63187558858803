@import "vars";
@import "fonts";
@import "static";
@import "header";
@import "main";
@import "footer";

html {
	font-size: 10px;
	@media(min-width: 1921px){
		font-size: 1.2vh;
	}
	background-color: #121110;
}


.btn {
	@include fb;
	width: 100%;
	max-width: 23rem;
	height: 7rem;
	text-transform: uppercase;
	font-size: 1.5rem;
	border-radius: 10rem;
	background:transparent;
	border:2px solid $active;
	cursor: pointer;
	color: $active;
	&:hover {
		background:$active;
		color: #000;
	}
}
.block_title {
	font-size: 4.4rem;
	display: block;
	text-align: center;
	font-family: $al;
}